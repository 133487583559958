<!--表单页面拷贝开始-->
<template>
    <div>
      <form-components @submitForm="submitForm">
        <template v-slot:content>
          <el-form-item label="Xxxx" >
              <el-col :span="8">
                <el-input v-model="xxxx" ></el-input>
              </el-col>
            </el-form-item>
        </template>
      </form-components>
    </div>
  </template>
  
  <script>
  import FormComponents from "@/components/form/FormComponents";
  import TripComplainApi from '@/api/TripComplainApi'
  import { navTo,queryParam } from "@/utils/NavigatorUtils";
  export default {
    name: "SaveTripComplainPage",
    props: {
      msg: String,
    },
    components: {
      FormComponents
    },
    data() {
      return {
        tripComplain:{
          xxxx:"",
        }
      }
    },
    created(){
      this.tripComplain = this.$route.params;
    },
    methods:{
      submitForm(){
        this.tripComplain.id>0?this.updateTripComplainById():this.addTripComplain()
      },
      addTripComplain(){
        TripComplainApi.saveTripComplain(this.tripComplain).then((resp)=>{
          if(resp.code === '200'){
            this.tripComplain={}
            this.$successMsg(resp.msg)
           
          }else{
            this.$errorMsg(resp.msg)
          }
        })
      },
      updateTripComplainById(){
        TripComplainApi.updateTripComplain(this.tripComplain).then((resp)=>{
          if(resp.code === '200'){
            this.user={}
            this.$successMsg(resp.msg);
             navTo(this,"/tripComplainList")
          }else{
            this.$errorMsg(resp.msg)
          }
        })
      }
    }
  };
  </script>
  
  
  <!--表单页面拷贝结束-->